import React, { useState } from "react";
import Dialog from "components/Dialog";

export default function ConfigSubscription(
  {
    classroom,
    formAuthenticityToken,
  },
): JSX.Element {
  const [showCancelDialog, setShowCancelDialog] = useState(false);

  const cardbrandImage = (brand) => {
    switch (brand) {
      case "amex":
      case "diners":
      case "discover":
      case "master":
      case "jcb":
      case "visa":
        return brand;
      default:
        return "others";
    }
  };

  return (
    <section
      id="subscription-setting"
      className="page__section subscription-setting"
    >
      <div className="page__container">
        <div className="page__section-head">
          <h2 className="page__title --level-2">
            ご利用状況
          </h2>
        </div>

        <div className="page__section-body">
          {classroom.currentPlatformSubscriptions.map(
            (currentPlatformSubscription) => (
              currentPlatformSubscription.activated
                ? (
                  <div
                    key={currentPlatformSubscription.id}
                    className={currentPlatformSubscription.platformPlan
                        .name == "trial"
                      ? "page__item page__item-info -color-attention"
                      : "page__item page__item-info -color-positive"}
                  >
                    <div className="page__item">
                      <div className="page__item-info-head">
                        <h3 className="page__title --level-3">
                          {currentPlatformSubscription.platformPlanName}

                          {currentPlatformSubscription.platformPlan
                              .name != "trial"
                            ? (
                              <span
                                className={currentPlatformSubscription
                                    .canceled
                                  ? "status-label --pending"
                                  : "status-label --active"}
                              >
                                {currentPlatformSubscription.canceled
                                  ? "自動更新停止中"
                                  : "自動更新"}
                              </span>
                            )
                            : null}
                        </h3>
                      </div>

                      <div className="page__item-info-body">
                        {currentPlatformSubscription.startDateYmda}
                        〜
                        {currentPlatformSubscription.endDateYmda}

                        {currentPlatformSubscription.canceled
                          ? (
                            <p>
                              {currentPlatformSubscription.platformPlan
                                  .name == "trial"
                                ? "トライアル"
                                : "期間"}
                              終了後も利用を続ける場合は支払い設定が必要です。
                            </p>
                          )
                          : (
                            <dl className="page__description-list">
                              {currentPlatformSubscription.platformPlan
                                      .name == "trial" &&
                                  !currentPlatformSubscription
                                    .endDateIsEndOfMonth
                                ? (
                                  <span>
                                    <div className="_item">
                                      <dt>初回支払日</dt>
                                      <dd>
                                        {currentPlatformSubscription
                                          .endDateYmda}
                                      </dd>
                                    </div>

                                    <div className="_item">
                                      <dt>初回請求額</dt>
                                      <dd>
                                        145円（日額）×トライアル終了日翌日から当月末までの日数
                                      </dd>
                                    </div>
                                  </span>
                                )
                                : null}

                              <div className="_item">
                                <dt>
                                  {currentPlatformSubscription
                                          .platformPlan.name == "trial" &&
                                      currentPlatformSubscription
                                        .endDateIsEndOfMonth
                                    ? "初回"
                                    : "次回"}
                                  支払日
                                </dt>

                                <dd>
                                  {currentPlatformSubscription
                                    .endDateYmda}
                                </dd>
                              </div>

                              <div className="_item">
                                <dt>
                                  {currentPlatformSubscription
                                          .platformPlan.name == "trial" &&
                                      currentPlatformSubscription
                                        .endDateIsEndOfMonth
                                    ? "初回"
                                    : "次回"}
                                  請求額
                                </dt>

                                <dd>月額利用料 4,378円（税込）</dd>
                              </div>
                            </dl>
                          )}
                      </div>

                      <div className="page__item-info-action">
                        <ul className="button-group">
                          <li className="_item">
                            <a
                              className="button -appearance-outlined -color-interactive -size-s"
                              href={classroom
                                .newManagementClassroomSettingsSubscriptionPath}
                            >
                              <div className="_body">
                                月額利用料の支払い設定
                              </div>
                            </a>
                          </li>

                          <li className="_item">
                            <a
                              className="button -appearance-outlined -color-interactive -size-s"
                              href={currentPlatformSubscription
                                .managementClassroomSettingsSubscriptionsPath}
                            >
                              <div className="_body">
                                支払い履歴
                              </div>
                            </a>
                          </li>

                          {!currentPlatformSubscription.canceled
                            ? (
                              <li className="_item">
                                <button
                                  className="button -appearance-transparent -color-negative -size-s"
                                  type="button"
                                  onClick={() => setShowCancelDialog(true)}
                                >
                                  <div className="_body">
                                    自動更新の停止
                                  </div>
                                </button>
                              </li>
                            )
                            : null}

                          {showCancelDialog
                            ? (
                              <Dialog
                                title="自動更新を停止しますか？"
                                description="月額利用料の支払いが行われない場合、利用制限として、受講者の新規入会や、請求・お知らせ・予定の新規作成ができなくなります。"
                                onCloseButtonClick={() =>
                                  setShowCancelDialog(false)}
                              >
                                <a
                                  className="button -appearance-transparent -size-m -color-negative"
                                  data-method="delete"
                                  rel="nofollow"
                                  href={currentPlatformSubscription
                                    .managementClassroomSettingsSubscriptionPath}
                                >
                                  <div className="_body">
                                    停止する
                                  </div>
                                </a>
                              </Dialog>
                            )
                            : null}
                        </ul>
                      </div>
                    </div>
                  </div>
                )
                : (
                  <div
                    key={currentPlatformSubscription.id}
                    className="page__item"
                  >
                    <div className="error-banner -elevation-0">
                      <div className="error-banner__leading">
                        <span
                          className="error-banner__icon icon"
                          data-icon="exclamation_on_triangle"
                        >
                        </span>
                      </div>

                      <div className="error-banner__body">
                        <div className="error-banner__title">
                          月額利用料が未払いです
                        </div>

                        <ul className="error-banner__list">
                          <li className="error-banner__item">
                            引き続きご利用を続ける場合は、月額利用料の支払いをお願いします。数日以内に決済が行われない場合、利用制限として以下の操作が行えなくなります。
                          </li>

                          <li className="error-banner__item">
                            <ul>
                              <li>受講者の新規入会</li>
                              <li>請求の新規作成</li>
                              <li>お知らせの新規作成</li>
                              <li>予定の新規作成</li>
                            </ul>
                          </li>
                        </ul>

                        <div className="error-banner__action">
                          <a
                            className="button -appearance-filled -color-interactive -size-s"
                            href={classroom
                              .newManagementClassroomSettingsSubscriptionPath}
                          >
                            <div className="_body">
                              月額利用料の支払い
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )
            ),
          )}

          {classroom.currentPlatformSubscriptions.length == 0
            ? (
              <div className="page__item">
                <div className="error-banner -elevation-0">
                  <div className="error-banner__leading">
                    <span
                      className="error-banner__icon icon"
                      data-icon="exclamation_on_triangle"
                    >
                    </span>
                  </div>

                  {classroom.paymentCardMethods.length > 0
                    ? (
                      <div className="error-banner__body">
                        <div className="error-banner__title">
                          月額利用料の支払いが完了していません
                        </div>

                        <ul className="error-banner__list">
                          <li className="error-banner__item">
                            登録済みクレジットカードで決済ができませんでした。クレジットカード情報をご確認の上、再登録をお願いいたします。再登録完了時に月額利用料の決済が行われます。
                          </li>
                        </ul>

                        <div className="error-banner__action">
                          <a
                            className="button -appearance-filled -color-interactive -size-s"
                            href={classroom
                              .newManagementClassroomSettingsSubscriptionPath}
                          >
                            <div className="_body">
                              クレジットカード再登録
                            </div>
                          </a>
                        </div>
                      </div>
                    )
                    : (
                      <div className="error-banner__body">
                        <div className="error-banner__title">
                          月額利用料が未払いです
                        </div>

                        <ul className="error-banner__list">
                          <li className="error-banner__item">
                            引き続きご利用を続ける場合は、月額利用料の支払いをお願いします。数日以内に決済が行われない場合、利用制限として以下の操作が行えなくなります。
                          </li>

                          <li className="error-banner__item">
                            <ul>
                              <li>受講者の新規入会</li>
                              <li>請求の新規作成</li>
                              <li>お知らせの新規作成</li>
                              <li>予定の新規作成</li>
                            </ul>
                          </li>
                        </ul>

                        <div className="error-banner__action">
                          <a
                            className="button -appearance-filled -color-interactive -size-s"
                            href={classroom
                              .newManagementClassroomSettingsSubscriptionPath}
                          >
                            <div className="_body">
                              月額利用料の支払い
                            </div>
                          </a>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            )
            : null}

          {classroom.paymentCardMethods.length > 0
            ? (
              <div className="page__item">
                <div className="page__data-head">
                  <h3 className="page__title --level-3">
                    登録済みのクレジットカード
                  </h3>
                </div>

                <div className="page__data-body">
                  <div className="credit-card-list">
                    <ul className="_list">
                      {classroom.paymentCardMethods.map((
                        paymentCardMethod,
                      ) => (
                        <li key={paymentCardMethod.id} className="_item">
                          <div>
                            <div className="_leading">
                              <div className="_media -size-s">
                                <div className="_thumbnail">
                                  {cardbrandImage(
                                      paymentCardMethod.card.brand,
                                    ) == "others"
                                    ? (
                                      <span className="credit-card-list__brand">
                                        {paymentCardMethod.card.brand}
                                      </span>
                                    )
                                    : (
                                      <img
                                        src={paymentCardMethod.card.logoUrl}
                                        alt={paymentCardMethod.card.brand}
                                      />
                                    )}
                                </div>
                              </div>
                            </div>

                            <div className="_body">
                              <div className="_title">
                                <span>
                                  **** **** **** {paymentCardMethod.card.last4}
                                </span>
                              </div>
                              <div className="_description">
                                {paymentCardMethod.card.expMonthFormatted} /
                                {paymentCardMethod.card.expYear}
                              </div>
                            </div>

                            {classroom.paymentCardMethods.length > 1
                              ? (
                                <div className="_trailing">
                                  <div className="credit-card-list__button">
                                    <form
                                      method="post"
                                      action={paymentCardMethod
                                        .managementClassroomSettingsPaymentCardPath}
                                    >
                                      <input
                                        type="hidden"
                                        name="_method"
                                        value="delete"
                                      />

                                      <input
                                        type="hidden"
                                        name="authenticity_token"
                                        value={formAuthenticityToken}
                                        autoComplete="off"
                                      />

                                      <button
                                        className="button -appearance-transparent -color-negative -size-s"
                                        type="submit"
                                      >
                                        <div>
                                          登録解除
                                        </div>
                                      </button>
                                    </form>
                                  </div>
                                </div>
                              )
                              : null}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            )
            : null}
        </div>
      </div>
    </section>
  );
}
